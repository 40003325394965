<template>
	<Fragment>
		<!-- main -->
		<div class="group row main">
			<!-- 사이트 이름 -->
			<div class="site-name">
				<div class="group row gap12">
					<!-- 아이콘 -->
					<div class="tile bd-c01"><i class="ic-24 ic-bd"> </i></div>
					<!-- 사이트 -->
					<div class="group column">
						<div class="group row">
							<span class="name">
								{{ site.siteName }}
								<div class="icon line" @click="toggleOpened"><i class="ic-16 ic-toggle"></i></div>
							</span>
							<template v-if="isToggle">
								<span class="btn_wrap">
									<span class="ic_set ic_open_con"> </span>
								</span>
							</template>
						</div>
						<a :href="`//${site.url}`" class="text-link basic">{{ site.url }}</a>
						<!-- // .site_url -> .text-link -->
					</div>
				</div>
			</div>
			<!-- 사용자 -->
			<div class="data-graph">
				<div class="group row" :class="{ state_caution: isUsedUserWarning }" :title="`${Math.round(usedUserRate)}%`">
					<!-- state_caution -> state-caution -->
					<div class="graph bar small">
						<div class="using" :style="`width: ${usedUserRate}%`"></div>
					</div>
					<div class="data_value">
						<span class="using">{{ site.usedUserCount }}명</span>
						<span class="notuse"> / {{ site.purchaseUserCount }}명</span>
					</div>
				</div>
			</div>
			<!-- 용량 -->
			<div class="data-graph">
				<div class="group column">
					<div class="group row" :class="{ state_caution: isUsedAccountQuotaWarning }">
						<span class="txt">계정</span>
						<div
							class="graph bar small"
							v-if="site.purchaseAccountQuota"
							:title="`${Math.round(usedAccountQuotaRate)}%`"
						>
							<div class="using" :style="`width: ${usedAccountQuotaRate}%`"></div>
						</div>
						<div class="data_value">
							<span class="using">{{ site.usedAccountQuota }} GB</span>
							<span class="notuse">
								/ {{ site.purchaseAccountQuota ? site.purchaseAccountQuota + ' GB' : '무제한' }}GB</span
							>
						</div>
					</div>
					<div class="group row" :class="{ state_caution: isUsedCompanyQuotaWarning }">
						<span class="txt">공용</span>
						<div
							class="graph bar small"
							v-if="site.purchaseCompanyQuota"
							:title="`${Math.round(usedCompanyQuotaRate)}%`"
						>
							<div class="using" :style="`width: ${usedCompanyQuotaRate}%`"></div>
						</div>
						<div class="data_value">
							<span class="using">{{ site.usedCompanyQuota }} GB</span>
							<span class="notuse">
								/ {{ site.purchaseCompanyQuota ? site.purchaseCompanyQuota + ' GB' : '무제한' }}GB</span
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- sub -->
		<div class="toggle-list-sub">
			<!-- -->
			<div class="group row gap8 mb-20">
				<div class="group column">
					<p class="title">서비스 이용기간</p>
					<p class="value">{{ _displayPeriod(site.period) }}</p>
				</div>
				<div class="group column">
					<p class="title">도메인</p>
					<p class="value">{{ site.domain }}</p>
				</div>
				<div class="group column">
					<p class="title">접속 URL</p>
					<p class="value">{{ site.url }}</p>
				</div>
				<div class="group column">
					<p class="title">그룹웨어 대표관리자 ID</p>
					<p class="value">{{ site.adminId }}</p>
				</div>
			</div>
			<div class="group row gap8 mb-20">
				<div class="group column">
					<p class="title">경영지원-경리회계 시작일</p>
					<p class="value">{{ site.accountStartDate }}</p>
				</div>
				<div class="group column">
					<p class="title">경영지원-경리회계 개설 사업자 번호</p>
					<p class="value">{{ site.businessNumber }}</p>
				</div>
				<div class="group column">
					<p class="title">경영지원-경리회계 대표관리자 ID</p>
					<div class="group-inp-btn">
						<input type="text" v-model="site.accountingAdminName" maxlength="64" />&nbsp;&nbsp;
						<button class="line" @click="changeAddonAdmin"><span class="group-inp-num">변경</span></button>
					</div>
					<p class="value"></p>
				</div>
				<div class="group column">
					<div class="group row gap8">
						<p class="title">경영지원-경리회계</p>
						<i class="ic-16 ic-info"
							>정보
							<div class="tooltip-layer bottom">OFF시 경영지원・경리회계 메뉴가 채널GNB에서 비노출 처리됩니다.</div>
						</i>
					</div>
					<template v-if="isAccountInstalled">
						<div class="group-toggle sm" :class="{ off: isAccountOff }" v-on:click="handleAccount">
							<div class="toggle left">
								<input type="radio" name="abc" />
								<label class="blind">사용</label>
							</div>
							<div class="toggle right">
								<input type="radio" name="abc" />
								<label class="blind">사용안함</label>
							</div>
							<div class="toggle btn"></div>
						</div>
					</template>
					<template v-else>
						<button class="line" v-on:click="handleAddAccounting">경리회계 추가</button>
					</template>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import backdrop from '@/components/common/backdrop';
import baseSite from '@/components/baseSite';
import SiteLayer from '@/components/SiteLayer';
import mixinsAlert from '@/components/common/mixinsAlert';
import { ADD_ACCOUNTING, HAS_ACCOUNT_PAID, OFF_ACCOUNTING, ON_ACCOUNTING } from '@/temlplates';

const WARNING_RATE = 90;

export default {
	mixins: [backdrop, baseSite, mixinsAlert],
	components: {},
	props: ['index', 'site', 'siteStatus', 'isToggle'],
	data() {
		return {};
	},
	computed: {
		remainUserCount() {
			return this.site.purchaseUserCount - this.site.usedUserCount;
		},
		usedUserRate() {
			return Math.min((this.site.usedUserCount / this.site.purchaseUserCount) * 100, 100);
		},
		usedAccountQuotaRate() {
			if (this.site.purchaseAccountQuota === 0) return 0;
			return Math.min((this.site.usedAccountQuota / this.site.purchaseAccountQuota) * 100, 100);
		},
		usedCompanyQuotaRate() {
			if (this.site.purchaseCompanyQuota === 0) return 0;
			return Math.min((this.site.usedCompanyQuota / this.site.purchaseCompanyQuota) * 100, 100);
		},
		isUsedUserWarning() {
			return WARNING_RATE < this.usedUserRate;
		},
		isUsedAccountQuotaWarning() {
			return WARNING_RATE < this.usedAccountQuotaRate;
		},
		isUsedCompanyQuotaWarning() {
			return WARNING_RATE < this.usedCompanyQuotaRate;
		},
		iconClass() {
			return `ic_bd${this.indexStr}`;
		},
		hasAccountPaidProduct() {
			const { site } = this;
			const paidCount =
				site.purchaseMeetingRoomCount +
				site.purchaseTaxBillCount +
				site.purchaseCompanyCreditCardCount +
				site.purchasePersonalCardCount +
				site.purchaseBankAccountCount;
			return paidCount > 0;
		},
	},
	methods: {
		toggleOpened() {
			this.$emit('siteInfoToggle', this.index);
		},
		handleLayerClick() {
			this.isOpenedLayer = false;
		},
		handleEditSite() {
			this.$modal.show(
				SiteLayer,
				{
					$store: this.$store, // vue-js-modal 버그. store 변경 발생 이후 참조가 끊어짐. store 를 넘겨줘야 사용 가능함.
					domains: this.siteStatus.domains,
					uuid: this.site.uuid,
					isCreate: false,
				},
				{
					classes: ['card', 'pop', 'xlarge'],
					width: '800px',
					height: 'auto',
					clickToClose: false,
				},
			);
		},
		handleDeleteSite() {
			const { sso } = this.$store.state.user;
			this.$ms.dialog.confirm(
				'사이트 삭제',
				this.isAccountInstalled
					? `<p class="txt">경리회계 사용시 사이트 삭제가 불가합니다.<br>대표전화 1577-3019로 문의주시기 바랍니다.</p>`
					: `<p class="txt">사이트를 삭제하면, 계정정보 및 사이트에 관련된 데이터가 모두 삭제되어 복구가 불가합니다.</p>
           <p class="txt_caution">삭제 하시겠습니까?</p>
           <div class="wrap_btn_box">
             <a href="https://care.daouoffice.co.kr/hc/ko/articles/900002278843-%EB%8D%B0%EC%9D%B4%ED%84%B0-%EB%B0%B1%EC%97%85-%EA%B8%B0%EB%8A%A5-%EC%82%AC%EC%9A%A9-%EA%B0%80%EC%9D%B4%EB%93%9C" target="_blank">
               <div class="btn_box">
                 <span class="txt">삭제 전 <strong>데이터 백업</strong> 받기 안내</span>
               </div>
             </a>
           </div>`,
				async () => {
					if (this.isAccountInstalled) {
						return;
					}
					const result = await this.$store.dispatch('deleteSite', {
						uuid: this.site.uuid,
						membershipId: sso.membershipId,
					});
					if (result.data.success) {
						return this.$ms.toast.show('삭제되었습니다.');
					} else {
						return this.$ms.toast.show('실패하였습니다.');
					}
				},
				() => {},
			);
		},
		handleAccount(e) {
			if (this.isAccountOff) {
				this.handleOnAccounting();
			} else {
				this.handleOffAccounting();
			}
		},
		handleAddAccounting() {
			this.alert({
				title: '경리회계 추가',
				contents: ADD_ACCOUNTING,
				buttons: [
					{
						text: '취소',
					},
				],
			});
		},
		handleOnAccounting() {
			this.alert({
				title: '경리회계 ON',
				contents: ON_ACCOUNTING,
				buttons: [
					{
						callback: async () => {
							const result = await this.$store.dispatch('putAccountOnOff', this._getAccountPayload(true));
							if (result.status === 200) {
								this.$ms.toast.show('경리회계 ON 되었습니다.');
							} else {
								this.$ms.toast.show('실패하였습니다.');
							}
						},
					},
					{},
				],
			});
		},
		handleOffAccounting() {
			this.alert({
				title: '경리회계 OFF',
				contents: this.hasAccountPaidProduct ? HAS_ACCOUNT_PAID : OFF_ACCOUNTING,
				buttons: [
					{
						callback: async () => {
							const result = await this.$store.dispatch('putAccountOnOff', this._getAccountPayload(false));
							if (result.status === 200) {
								this.$ms.toast.show('경리회계 OFF 되었습니다.');
							} else {
								this.$ms.toast.show('실패하였습니다.');
							}
						},
					},
					{},
				],
			});
		},
		_getAccountPayload(isOn) {
			let params = {
				code: 'ACCOUNT',
				use: isOn,
			};
			return {
				id: this.$store.state.user.membership.id,
				uuid: this.site.uuid,
				params: params,
			};
		},
		_displayPeriod(period) {
			if (period && typeof period === 'string') {
				return period.replace('2099-12-31', '만료일 없음');
			}
		},
		async changeAddonAdmin() {
			this.alert({
				title: '경리회계 관리자 변경',
				contents: '경리회계 관리자를 변경하시겠습니까?',
				buttons: [
					{
						callback: async () => {
							const params = {
								companyUid: this.site.uuid,
								adminId: this.site.accountingAdminName,
								adminEmail: `${this.site.accountingAdminName}@${this.site.domain}`,
							};
							const result = await this.$store.dispatch('changeAddonAdmin', params);

							if (result.status === 200) {
								this.$ms.toast.show(result.message);
							} else {
								this.$ms.toast.show(result.errorDescription);
							}
						},
					},
					{},
				],
			});
		},
	},
};
</script>
