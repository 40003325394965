<template>
	<Fragment>
		<div class="container">
			<div class="body-contents system">
				<h2 class="heading-1-b">
					시스템 현황
					<i class="ic-24 ic-info"
						>정보
						<div class="tooltip-layer left">
							다우오피스 그룹웨어 서비스를 사용중인 멤버십중에서도 다우오피스 운영시스템과 실시간 통신이 가능할 경우에만
							정확한 현황 정보를 확인할 수 있습니다. (단독, 설치, 구축형은 실시간 사용량 체크가 불가능합니다.)
						</div>
					</i>
				</h2>
				<div class="tab-wrap">
					<membership-tab @getSelected="getSelected"></membership-tab>
					<site-status
						v-if="this.selectMembershipId"
						:key="this.selectMembershipId"
						:membership-id="this.selectMembershipId"
					></site-status>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import SiteStatus from '@/views/user/myStore/SiteStatus';
import MembershipTab from '@/components/common/MembershipTab';

export default {
	components: {
		SiteStatus,
		MembershipTab,
	},
	data() {
		return {
			selectMembershipId: '',
		};
	},
	watch: {},
	async created() {},
	computed: {},
	methods: {
		getSelected(membershipId) {
			this.selectMembershipId = membershipId;
		},
	},
};
</script>
<style scoped>
/*@import '../../../assets/css/system.css';*/
</style>
