<template>
	<Fragment>
		<div class="tab-contents section-wrap gap64">
			<div class="section">
				<h3 class="heading-3-b mb-20">서비스 사용 현황</h3>
				<div class="contents">
					<div class="group column gap24">
						<!-- .container.wrap_horizon_graph ->  group column -->
						<!-- 그래프 부분 -->
						<div class="group row col2 gap40 align-start">
							<!-- layout_col2 -> group row col2 -->
							<!-- 그래프 1 -->
							<div class="group column gap8">
								<!-- wrap_col -> group column gap8 -->
								<div class="group row space-between">
									<!-- graph_tit -> group row -->
									<span class="text-medium">
										<!-- tit -> text-medium -->
										실제 사용자 수 / 계약 사용자 수
										<span class="text-sub">(무료제공 포함)</span>
										<!-- 설명부분 추가 -->
									</span>
									<span class="data">
										<strong class="txt primary">{{ siteStatus.usedUserCount }}명</strong>
										/ {{ siteStatus.purchaseUserCount }}명
									</span>
								</div>
								<div class="graph bar">
									<!-- graph-value 클래스 추가 -->
									<div
										class="graph-value using"
										:title="`${Math.round(usedUserRate)}%`"
										:style="`width: ${Math.round(usedUserRate)}%`"
									>
										{{ Math.round(usedUserRate) }}%
									</div>
									<div
										class="graph-value notuse"
										:title="`${Math.round(remainUserRate)}%`"
										:style="`width: ${Math.round(remainUserRate)}%`"
									>
										{{ Math.round(remainUserRate) }}%
									</div>
								</div>
							</div>
							<!-- // 그래프 1 -->
							<!-- 그래프 2 -->
							<div class="group column gap8">
								<!-- wrap_col -> group column gap8 -->
								<div class="group row space-between">
									<!-- graph_tit -> group row -->
									<span class="text-medium">
										<!-- tit -> text-medium -->
										실제 사용 용량 / 계약 사용 용량
										<span class="text-sub">(무료제공 포함)</span>
										<!-- 설명부분 추가 -->
									</span>
									<span class="data">
										<strong class="txt primary">{{ siteStatus.usedQuota }} GB</strong>
										/ {{ siteStatus.purchaseQuota }} GB
									</span>
								</div>
								<div class="graph bar">
									<div
										class="graph-value using"
										:title="`${Math.round(usedQuotaRate)}%`"
										:style="`width: ${Math.round(usedQuotaRate)}%`"
									>
										{{ Math.round(usedQuotaRate) }}%
									</div>
									<div
										class="graph-value notuse"
										:title="`${Math.round(remainVolumeRate)}%`"
										:style="`width: ${Math.round(remainVolumeRate)}%`"
									>
										{{ Math.round(remainVolumeRate) }}%
									</div>
								</div>
							</div>
							<!-- // 그래프 2 -->
						</div>
						<div class="group column gap8">
							<!-- // additional_services -->
							<!-- <div class="graph_tit"> -->
							<!-- <span class="tit">부가서비스</span> -->
							<p class="text-medium">옵션 상품</p>
							<!-- </div> -->
							<ul class="group row table-form">
								<!-- service_list -> group row -->
								<li class="group row space-between">
									<!-- <span class="tit">법인카드</span> -->
									<label class="form-label">
										<span class="txt">법인카드</span>
									</label>
									<!-- <span class="data">0개 / 0개</span> -->
									<div class="form-value">
										<span class="txt"
											>{{ siteStatus.usedCompanyCreditCardCount }}개 /
											{{ siteStatus.purchaseCompanyCreditCardCount }}개</span
										>
									</div>
								</li>
								<!-- service_list -> group row -->
								<li class="group row space-between">
									<!-- <span class="tit">개인카드 사용자</span> -->
									<label class="form-label">
										<span class="txt">개인카드 사용자</span>
									</label>
									<!-- <span class="data">0개 / 0개</span> -->
									<div class="form-value">
										<span class="txt"
											>{{ siteStatus.usedPersonalCardCount }}개 / {{ siteStatus.purchasePersonalCardCount }}개</span
										>
									</div>
								</li>
								<!-- service_list -> group row -->
								<li class="group row space-between">
									<!-- <span class="tit">세금계산서 발행</span> -->
									<label class="form-label">
										<span class="txt">세금계산서 발행</span>
									</label>

									<!-- <span class="data">0개 / 0개</span> -->
									<div class="form-value">
										<span class="txt"
											>{{ siteStatus.usedTaxBillCount }}개 / {{ siteStatus.purchaseTaxBillCount }}개</span
										>
									</div>
								</li>
								<!-- service_list -> group row -->
								<li class="group row space-between">
									<!-- <span class="tit">은행계좌 즉시조회</span> -->
									<label class="form-label">
										<span class="txt">은행계좌 즉시조회</span>
									</label>

									<!-- <span class="data">0개 / 0개</span> -->
									<div class="form-value">
										<span class="txt"
											>{{ siteStatus.usedBankAccountCount }}개 / {{ siteStatus.purchaseBankAccountCount }}개</span
										>
									</div>
								</li>
								<li class="group row space-between">
									<label class="form-label">
										<span class="txt">전자계약</span>
									</label>
									<div class="form-value">
										<span class="txt"
											>{{ siteStatus.usedElectronicContractCount }}개 /
											{{ siteStatus.purchaseElectronicContractCount }}개</span
										>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- // contents   -->
			</div>
			<div v-if="isNotSupported" class="section-wrap center large">
				<div class="section">
					<div class="img-graphic text-center mb-20">
						<i class="ic-64 ic-info"></i>
					</div>
					<p class="guide-text grey large text-center mb-12">
						본 화면은 클라우드 공유형 및 경영지원(경리회계) 사용 고객에게 제공하고 있습니다. <br />
						사용을 희망하시는 경우, 영업 담당자에게 문의하시기 바랍니다.<br />
					</p>
					<p class="guide-text grey medium text-center"></p>
				</div>
			</div>
			<div v-else class="section">
				<h3 class="heading-3-b mb-20">사이트 상세 현황</h3>
				<!-- <ul class="wrap_title_btn"> ???????
            </ul> -->
				<ul class="util rgt group row gap8" v-if="isSystemEditable">
					<button class="line icon-text mini" @click="handleCreateSite">
						<i class="ic-16 ic-grow"></i>사이트 추가
					</button>
					<button class="line mini" @click="handleSettingQuota">쿼터 설정</button>
				</ul>
				<div class="contents">
					<!-- toggle type list-->
					<div class="basic-toggle-list">
						<ul class="list-head">
							<li class="group row">
								<div class="site-name"><span class="txt">이름</span></div>
								<div class="data-graph"><span class="txt">사용자</span></div>
								<div class="data-graph"><span class="txt">용량</span></div>
								<!-- 버튼 들어가는 column -->
								<!-- <div class="btn-area">
									<span class="txt"></span>
								</div> -->
							</li>
							<!-- <div class="tb_list">
                  <div class="tb_tit">
                      <div class="label site_name">이름</div>
                      <div class="label user_graph">사용자</div>
                      <div class="label data_graph">용량</div>
                      <div class="label more_btn"> </div>
              </div> -->
						</ul>
						<ul class="list-body">
							<!-- list : close -->
							<ul class="list-body">
								<li
									class="toggle-list"
									v-for="(site, index) in editableSites"
									:key="index"
									:class="{ open: siteOpenSelected == index }"
								>
									<Site
										:key="site.uuid"
										:index="index"
										:site="site"
										:siteStatus="siteStatus"
										:isToggle="index == siteOpenSelected"
										@siteInfoToggle="siteInfoToggle"
									></Site>
								</li>
							</ul>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import Site from '@/components/Site.vue';
import SiteLayer from '@/components/SiteLayer';
import QuotaSettingLayer from '@/components/QuotaSettingLayer';

export default {
	props: {
		membershipId: Number,
	},
	components: {
		Site,
	},
	data() {
		return {
			siteOpenSelected: null,
		};
	},
	async created() {
		try {
			await this.$store.dispatch('fetchMembership', this.membershipId);
			await this.$store.dispatch('fetchSiteStatus', this.membershipId);
		} catch (e) {
			console.log(`fetch site status error: ${e}`);
		}
	},
	computed: {
		siteStatus() {
			return this.$store.state.user.siteStatus;
		},
		remainUserCount() {
			return this.siteStatus.purchaseUserCount - this.siteStatus.usedUserCount;
		},
		usedUserRate() {
			return Math.min((this.siteStatus.usedUserCount / this.siteStatus.purchaseUserCount) * 100, 100) || 0;
		},
		remainUserRate() {
			return (this.remainUserCount / this.siteStatus.purchaseUserCount) * 100 || 0;
		},
		remainVolume() {
			return this.siteStatus.purchaseQuota - this.siteStatus.usedQuota;
		},
		usedQuotaRate() {
			return Math.min((this.siteStatus.usedQuota / this.siteStatus.purchaseQuota) * 100, 100) || 0;
		},
		remainVolumeRate() {
			return (this.remainVolume / this.siteStatus.purchaseQuota) * 100 || 0;
		},
		isSystemEditable() {
			return !this.$store.getters.isSaaS && !this.$store.getters.isSaaSReseller;
		},
		isSystemViewable() {
			// return !(this.$store.getters.isInstall && this.$store.getters.isSiteAdmin);
			return true;
		},
		editableSites() {
			return this.siteStatus.sites;
		},
		isNotSupported() {
			return this.$store.getters.isInstall && this.siteStatus.sites?.length === 0; //사이트가 없고, 멤버십의 서비스타입이 공유형이 아니면 레이어 노출
		},
	},
	methods: {
		siteInfoToggle(index) {
			if (this.siteOpenSelected === index) {
				this.siteOpenSelected = null;
			} else {
				this.siteOpenSelected = index;
			}
		},
		handleCreateSite() {
			this.$modal.show(
				SiteLayer,
				{
					membershipId: this.membershipId,
					store: this.$store, // vue-js-modal 버그. store 변경 발생 이후 참조가 끊어짐. store 를 넘겨줘야 사용 가능함.
					domains: this.siteStatus.domains,
					isCreate: true,
				},
				{
					classes: ['card', 'pop', 'xlarge'],
					width: '800px',
					height: 'auto',
					clickToClose: false,
				},
			);
		},
		handleSettingQuota() {
			this.$modal.show(
				QuotaSettingLayer,
				{
					membershipId: this.membershipId,
					store: this.$store, // vue-js-modal 버그. store 변경 발생 이후 참조가 끊어짐. store 를 넘겨줘야 사용 가능함.
				},
				{
					classes: ['card', 'pop', 'xlarge'],
					width: '800px',
					height: 'auto',
					clickToClose: false,
				},
			);
		},
	},
};
</script>
