<template>
	<Fragment>
		<div class="tab-contents">
			<div class="tab-table group column">
				<!-- tab_body_fix -> tab-table -->
				<!-- wrapper <div class="com_form">  삭제 -->
				<!-- wrapper <div class="wrap_row">  삭제 -->
				<div class="group row">
					<!-- row -> group row -->
					<label class="form_tit">
						<!-- form_tit cell -> form_tit -->
						<span class="txt">총 사용자 수</span>
					</label>
					<div class="form_data">
						<!-- form_data -> form_data -->
						<span class="txt">{{ assignedUserCount }}명 / {{ siteStatus.purchaseUserCount }}명</span>
						<span class="capacity" :class="{ caution: remainingUserCount < 0 }" v-if="remainingUserCount !== 0"
							>({{ displayRemainingCount(remainingUserCount) }}명{{ remainingLabel(remainingUserCount) }})</span
						>
						<!-- //[D] 할당/초과할당 메시지 : 기본은 .capacity 경고메시지의 경우 + .caution -->
					</div>
					<label class="form_tit">
						<span class="txt">총 용량</span>
					</label>
					<div class="form_data">
						<span class="txt">{{ assignedQuota }} GB / {{ siteStatus.purchaseQuota }}</span>
						<span class="capacity" :class="{ caution: remainingQuota < 0 }" v-if="remainingQuota !== 0"
							>({{ displayRemainingCount(remainingQuota) }}GB{{ remainingLabel(remainingQuota) }})</span
						>
					</div>
				</div>
				<div class="group row">
					<!-- row -> group row -->
					<label class="form_tit">
						<!-- form_tit cell -> form_tit -->
						<span class="txt">법인카드</span>
					</label>
					<div class="form_data">
						<!-- form_data -> form_data -->
						<span class="txt">
							{{ assignedCompanyCreditCardCount }} 개 / {{ siteStatus.purchaseCompanyCreditCardCount }} 개
						</span>
						<span
							class="capacity"
							:class="{ caution: remainingCompanyCreditCardCount < 0 }"
							v-if="remainingCompanyCreditCardCount !== 0"
							>({{ displayRemainingCount(remainingCompanyCreditCardCount) }}개{{
								remainingLabel(remainingCompanyCreditCardCount)
							}})
						</span>
					</div>
					<label class="form_tit">
						<span class="txt">개인카드 사용자</span>
					</label>
					<div class="form_data">
						<span class="txt">{{ assignedPersonalCardCount }}개 / {{ siteStatus.purchasePersonalCardCount }}개</span>
						<span
							class="capacity"
							:class="{ caution: remainingPersonalCardCount < 0 }"
							v-if="remainingPersonalCardCount !== 0"
						>
							({{ displayRemainingCount(remainingPersonalCardCount) }}개{{
								remainingLabel(remainingPersonalCardCount)
							}})
						</span>
					</div>
				</div>
				<div class="group row">
					<!-- row -> group row -->
					<label class="form_tit">
						<!-- form_tit cell -> form_tit -->
						<span class="txt">화상대화</span>
					</label>
					<div class="form_data">
						<!-- form_data -> form_data -->
						<span class="txt">{{ assignedMeetingRoomCount }}개 / {{ siteStatus.purchaseMeetingRoomCount }}개</span>
						<span
							class="capacity"
							:class="{ caution: remainingMeetingRoomCount < 0 }"
							v-if="remainingMeetingRoomCount !== 0"
						>
							({{ displayRemainingCount(remainingMeetingRoomCount) }}개{{ remainingLabel(remainingMeetingRoomCount) }})
						</span>
					</div>
					<label class="form_tit">
						<span class="txt">세금계산서</span>
					</label>
					<div class="form_data">
						<span class="txt">{{ assignedTaxBillCount }}개 / {{ siteStatus.purchaseTaxBillCount }}개</span>
						<span class="capacity" :class="{ caution: remainingTaxBillCount < 0 }" v-if="remainingTaxBillCount !== 0">
							({{ displayRemainingCount(remainingTaxBillCount) }}개{{ remainingLabel(remainingTaxBillCount) }})
						</span>
					</div>
				</div>
				<div class="group row">
					<!-- row -> group row -->
					<label class="form_tit">
						<!-- form_tit cell -> form_tit -->
						<span class="txt">은행계좌 즉시조회</span>
					</label>
					<div class="form_data">
						<!-- form_data -> form_data -->
						<span class="txt">{{ assignedBankAccountCount }}개 / {{ siteStatus.purchaseBankAccountCount }}개</span>
						<span
							class="capacity"
							:class="{ caution: remainingBankAccountCount < 0 }"
							v-if="remainingBankAccountCount !== 0"
						>
							({{ displayRemainingCount(remainingBankAccountCount) }}개{{ remainingLabel(remainingBankAccountCount) }})
						</span>
					</div>
				</div>
				<!-- // </div> wrapper <div class="com_form">  삭제-->
				<!-- // </div> wrapper <div class="wrap_row">  삭제-->
			</div>
			<div class="tab-scroll-cont">
				<SiteQuota
					v-for="site in sitesSortByNewSiteFirst"
					:key="site.uuid"
					:index="site.index"
					:isNew="site.index === -1"
					:site="site"
					:siteStatus="siteStatus"
					v-on:changeSite="handleSiteChange"
				></SiteQuota>
			</div>
		</div>
	</Fragment>
</template>

<script>
import SiteQuota from '@/components/SiteQuota';
import baseSiteStatus from '@/components/baseSiteStatus';

export default {
	components: { SiteQuota },
	mixins: [baseSiteStatus],
	props: ['siteStatus'],
	created() {
		console.log(
			`[사용자] 사용중: ${this.siteStatus.usedUserCount} / 할당: ${this.assignedUserCount} / 구입: ${this.siteStatus.purchaseUserCount} / 할당가능: ${this.remainingUserCount}`,
		);
		console.log(
			`[용량] 사용중: ${this.siteStatus.usedQuota} / 할당: ${this.assignedQuota} / 구입: ${this.siteStatus.purchaseQuota} / 할당가능: ${this.remainingQuota}`,
		);
		console.log(
			`[법인카드] 사용중: ${this.siteStatus.usedCompanyCreditCardCount} / 할당: ${this.assignedCompanyCreditCardCount} / 구입: ${this.siteStatus.purchaseCompanyCreditCardCount} / 할당가능: ${this.remainingCompanyCreditCardCount}`,
		);
		console.log(
			`[개인카드] 사용중: ${this.siteStatus.usedPersonalCardCount} / 할당: ${this.assignedPersonalCardCount} / 구입: ${this.siteStatus.purchasePersonalCardCount} / 할당가능: ${this.remainingPersonalCardCount}`,
		);
		console.log(
			`[화상대화] 사용중: ${this.siteStatus.usedMeetingRoomCount} / 할당: ${this.assignedMeetingRoomCount} / 구입: ${this.siteStatus.purchaseMeetingRoomCount} / 할당가능: ${this.remainingMeetingRoomCount}`,
		);
		console.log(
			`[세금계산서] 사용중: ${this.siteStatus.usedTaxBillCount} / 할당: ${this.assignedTaxBillCount} / 구입: ${this.siteStatus.purchaseTaxBillCount} / 할당가능: ${this.remainingTaxBillCount}`,
		);
		console.log(
			`[은행계좌] 사용중: ${this.siteStatus.usedBankAccountCount} / 할당: ${this.assignedBankAccountCount} / 구입: ${this.siteStatus.purchaseBankAccountCount} / 할당가능: ${this.remainingBankAccountCount}`,
		);
	},
	computed: {
		sitesSortByNewSiteFirst() {
			const sites = this.siteStatus.sites.map((site, index) => {
				site.index = site.uuid ? index : -1;
				return site;
			});
			sites.sort((a, b) => a.index - b.index);
			return sites;
		},
	},
	methods: {
		displayRemainingCount(remaining) {
			return remaining < 0 ? remaining * -1 : remaining;
		},
		remainingLabel(remaining) {
			if (remaining === 0) return '';
			if (remaining > 0) {
				return ' 할당가능';
			} else {
				return ' 초과할당됨';
			}
		},
	},
};
</script>
