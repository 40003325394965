import eventBus from '@/utils/bus';
import { hasElement } from '@/utils/utils';

export default {
	data() {
		return {
			isOpenedLayer: false,
		};
	},
	mounted() {
		eventBus.$on('backdrop', async (element) => {
			await this.$nextTick();
			if (!this.$refs.backdropParent || !element) return;
			if (!hasElement(this.$refs.backdropParent, element)) {
				this._hideLayer();
			}
		});
	},
	methods: {
		handleBackdropButtonClick(e) {
			this.isOpenedLayer = !this.isOpenedLayer;
			eventBus.$emit('backdrop', e.target);
		},
		handleBackdrop(e) {
			eventBus.$emit('backdrop', e.target);
		},
		_hideLayer() {
			this.isOpenedLayer = false;
		},
	},
};
