<template>
	<Fragment>
		<div class="header">
			<h1>쿼터 설정</h1>
			<button class="icon" @click="handleCancel">
				<i class="ic-24 ic-close"></i>
				close
			</button>
		</div>
		<div class="contents-wrap">
			<div class="tab-wrap">
				<QuotaSetting ref="quotaSetting" :siteStatus="siteStatus"></QuotaSetting>
			</div>
		</div>
	</Fragment>
</template>

<script>
import _ from 'lodash';
import QuotaSetting from '@/components/QuotaSetting';
import baseSiteStatus from '@/components/baseSiteStatus';
import baseSite from '@/components/baseSite';

const ERROR = {
	quotaSetting: {
		remainingUserCount: '',
		remainingQuota: '',
		remainingCompanyCreditCardCount: '',
		remainingMeetingRoomCount: '',
	},
};

export default {
	name: 'QuotaSettingLayer',
	components: { QuotaSetting },
	mixins: [baseSiteStatus, baseSite],
	props: ['membershipId', 'store'],
	data() {
		return {
			siteStatus: _.cloneDeep(this.store.state.user.siteStatus),
			error: _.cloneDeep(ERROR),
		};
	},
	methods: {
		async handleSave() {
			this.error = _.cloneDeep(ERROR);
			if (!this.validateQuotaSetting() || !this.validateSites()) return;

			const result = await this.store.dispatch('setSiteStatus', {
				membershipId: this.membershipId,
				siteStatus: this.siteStatus,
			});
			if (result.data.success) {
				this.$ms.toast.show('변경되었습니다.');
			} else {
				this.$ms.toast.show('실패하였습니다.');
			}
			this.$emit('close');
		},
		handleCancel() {
			this.$emit('close');
		},
	},
};
</script>
