import baseComponent from '@/components/baseComponent';

export default {
	mixins: [baseComponent],
	computed: {
		assignedUserCount() {
			let count = 0;
			this.siteStatus.sites.forEach((site) => {
				const number = parseInt(site.purchaseUserCount);
				if (!isNaN(number)) count += number;
			});
			return count;
		},
		assignedQuota() {
			let count = 0;
			this.siteStatus.sites.forEach((site) => {
				const accountNumber = parseInt(site.purchaseAccountQuota);
				if (!isNaN(accountNumber)) count += accountNumber;
				const companyNumber = parseInt(site.purchaseCompanyQuota);
				if (!isNaN(companyNumber)) count += companyNumber;
			});
			return count;
		},
		assignedCompanyCreditCardCount() {
			let count = 0;
			this.siteStatus.sites.forEach((site) => {
				const number = parseInt(site.purchaseCompanyCreditCardCount);
				if (!isNaN(number)) count += number;
			});
			return count;
		},
		assignedPersonalCardCount() {
			let count = 0;
			this.siteStatus.sites.forEach((site) => {
				const number = parseInt(site.purchasePersonalCardCount);
				if (!isNaN(number)) count += number;
			});
			return count;
		},
		assignedMeetingRoomCount() {
			let count = 0;
			this.siteStatus.sites.forEach((site) => {
				const number = parseInt(site.purchaseMeetingRoomCount);
				if (!isNaN(number)) count += number;
			});
			return count;
		},
		assignedTaxBillCount() {
			let count = 0;
			this.siteStatus.sites.forEach((site) => {
				const number = parseInt(site.purchaseTaxBillCount);
				if (!isNaN(number)) count += number;
			});
			return count;
		},
		assignedBankAccountCount() {
			let count = 0;
			this.siteStatus.sites.forEach((site) => {
				const number = parseInt(site.purchaseBankAccountCount);
				if (!isNaN(number)) count += number;
			});
			return count;
		},
		remainingUserCount() {
			return this.siteStatus.purchaseUserCount - this.assignedUserCount;
		},
		remainingQuota() {
			const { siteStatus } = this;
			return siteStatus.purchaseQuota - this.assignedQuota;
		},
		remainingCompanyCreditCardCount() {
			return this.siteStatus.purchaseCompanyCreditCardCount - this.assignedCompanyCreditCardCount;
		},
		remainingPersonalCardCount() {
			return this.siteStatus.purchasePersonalCardCount - this.assignedPersonalCardCount;
		},
		remainingMeetingRoomCount() {
			return this.siteStatus.purchaseMeetingRoomCount - this.assignedMeetingRoomCount;
		},
		remainingTaxBillCount() {
			return this.siteStatus.purchaseTaxBillCount - this.assignedTaxBillCount;
		},
		remainingBankAccountCount() {
			return this.siteStatus.purchaseBankAccountCount - this.assignedBankAccountCount;
		},
	},
	methods: {
		handleSiteChange(data) {
			const { sites } = this.siteStatus;
			sites.splice(sites.indexOf(data.site), 1, Object.assign({}, data.site));
		},
		validateQuotaSetting() {
			const { quotaSetting } = this.error;
			if (this.remainingUserCount < 0) {
				quotaSetting.remainingUserCount = true;
			}
			if (this.remainingQuota < 0) {
				quotaSetting.remainingQuota = true;
			}
			if (this.remainingCompanyCreditCardCount < 0) {
				quotaSetting.remainingCompanyCreditCardCount = true;
			}
			if (this.remainingPersonalCardCount < 0) {
				quotaSetting.remainingPersonalCardCount = true;
			}
			if (this.remainingMeetingRoomCount < 0) {
				quotaSetting.remainingMeetingRoomCount = true;
			}
			if (this.remainingTaxBillCount < 0) {
				quotaSetting.remainingTaxBillCount = true;
			}
			if (this.remainingBankAccountCount < 0) {
				quotaSetting.remainingBankAccountCount = true;
			}
			return this.checkValid(quotaSetting);
		},
	},
};
