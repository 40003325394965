<template>
	<Fragment>
		<div class="group column">
			<!-- // wrap_quater_setting -> group column --><!--  site 01 -->
			<!-- site new -->
			<div class="group row site">
				<!-- // row -> group row site -->
				<div class="site_name group row">
					<!-- // site_name -> + group row -->
					<template v-if="isNew">
						<span class="tile bd-new sm">
							<i class="ic-24 ic-bd"></i>
						</span>
					</template>
					<template v-else>
						<span class="tile bd-c01 sm">
							<i class="ic-24 ic-bd"></i>
						</span>
					</template>
					<!-- // ic_building ic_bd_new -> tile bd-#{color칼라번호} sm -->
					<span class="txt">{{ site.siteName }}</span>
				</div>
				<div class="site_data group column">
					<!-- // site_data -> + group column -->
					<!-- <div class="com_form"> 삭제 -->
					<!-- <div class="wrap_row"> 삭제 -->
					<div class="group row">
						<!-- // + group -->
						<label class="form_tit">
							<span class="txt">사용자 수</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ site.usedUserCount }}명 /</span>
							<div class="com_ipt number">
								<!-- <input class="ipt_normal" type="number" name="quantity" min="0" max="300" step="10">
                <span class="txt">명</span> -->
								<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
								<span class="com_number">
									<button
										class="minus"
										:disabled="site.purchaseUserCount == 0"
										:data-opetaion-type="this.operation.minus"
										@click="handleSitePurchaseUserCount"
									>
										<span class="ic_set ic_minus"></span>
									</button>
									<input
										class="ipt_normal"
										:class="{ invalid: siteQuotaError.purchaseUserCount }"
										type="number"
										name="quantity"
										data-type="purchaseUserCount"
										:min="site.usedUserCount"
										:value="site.purchaseUserCount"
										@change="handleSiteChangeElement"
										@keyup="handleSiteChangeElement"
										@mouseup="handleSiteChangeElement"
									/>
									<button class="plus" :data-opetaion-type="this.operation.plus" @click="handleSitePurchaseUserCount">
										<span class="ic_set ic_plus"></span>
									</button>
								</span>
								<span class="txt">명</span>
							</div>
							<p class="text-detail invalid" v-if="siteQuotaError.purchaseUserCount">
								<i class="ic-16 ic-invalid"></i>
								{{ siteQuotaError.purchaseUserCount }}
							</p>
						</div>
					</div>
					<!-- </div> // <div class="wrap_row"> 삭제 -->
					<!-- <div class="wrap_row"> -->
					<div class="group row">
						<label class="form_tit">
							<span class="txt">계정 용량</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form @submit.prevent="() => {}">
									<p>
										<input
											type="radio"
											:id="`accountQuotaUnlimited${site.uuid}`"
											:name="`accountQuota${site.uuid}`"
											:checked="isUnlimitedAccountQuota"
										/>
										<label
											:for="`accountQuotaUnlimited${site.uuid}`"
											@click="handleAccountQuotaLimitClick"
											data-is-limited="false"
											>무제한</label
										>
									</p>
									<div class="group row">
										<!-- <p> -->
										<input
											type="radio"
											:id="`accountQuotaLimited${site.uuid}`"
											:name="`accountQuota${site.uuid}`"
											:checked="!isUnlimitedAccountQuota"
										/>
										<label
											:for="`accountQuotaLimited${site.uuid}`"
											@click="handleAccountQuotaLimitClick"
											data-is-limited="true"
											>직접 입력</label
										>
										<span class="txt">{{ site.usedAccountQuota }}GB /</span>
										<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
										<div class="com_ipt number">
											<span class="com_number">
												<button
													class="minus"
													:disabled="purchaseAccountQuota == 0 || isUnlimitedAccountQuota"
													:data-opetaion-type="this.operation.minus"
													@click="handleSitePurchaseAccountQuota"
												>
													<span class="ic_set ic_minus"></span>
												</button>
												<input
													class="ipt_normal"
													type="number"
													name="quantity"
													data-type="purchaseAccountQuota"
													:class="{ invalid: siteQuotaError.purchaseAccountQuota }"
													:min="minAccountQuota"
													:disabled="isUnlimitedAccountQuota"
													:value="purchaseAccountQuota"
													@change="handleSiteChangeElement"
													@keyup="handleSiteChangeElement"
													@mouseup="handleSiteChangeElement"
												/>
												<button
													class="plus"
													:disabled="isUnlimitedAccountQuota"
													:data-opetaion-type="this.operation.plus"
													@click="handleSitePurchaseAccountQuota"
												>
													<span class="ic_set ic_plus"></span>
												</button>
											</span>
											<span class="txt">GB</span>
										</div>
										<!-- </p> -->
										<p class="text-detail invalid" v-if="siteQuotaError.purchaseAccountQuota">
											<i class="ic-16 ic-invalid"></i>
											{{ siteQuotaError.purchaseAccountQuota }}
										</p>
									</div>
								</form>
							</div>
						</div>
					</div>
					<!-- </div> // <div class="wrap_row"> 삭제 -->
					<!-- <div class="wrap_row"> -->
					<div class="group row">
						<label class="form_tit">
							<span class="txt">공용 용량</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form @submit.prevent="() => {}">
									<p>
										<input
											type="radio"
											name="companyQuota"
											:id="`companyQuotaUnlimited${site.uuid}`"
											:checked="isUnlimitedCompanyQuota"
										/>
										<label
											:for="`companyQuotaUnlimited${site.uuid}`"
											@click="handleCompanyQuotaLimitClick"
											data-is-limited="false"
											>무제한</label
										>
									</p>
									<div class="group row">
										<!-- <p> -->
										<input
											type="radio"
											name="companyQuota"
											:id="`companyQuotaLimited${site.uuid}`"
											:checked="!isUnlimitedCompanyQuota"
										/>
										<label
											:for="`companyQuotaLimited${site.uuid}`"
											@click="handleCompanyQuotaLimitClick"
											data-is-limited="true"
											>직접 입력</label
										>
										<span class="txt">{{ site.usedCompanyQuota }}GB /</span>
										<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
										<div class="com_ipt number">
											<span class="com_number">
												<button
													class="minus"
													:disabled="purchaseCompanyQuota == 0 || isUnlimitedCompanyQuota"
													:data-opetaion-type="this.operation.minus"
													@click="handleSitePurchaseCompanyQuota"
												>
													<span class="ic_set ic_minus"></span>
												</button>
												<input
													class="ipt_normal"
													type="number"
													name="quantity"
													data-type="purchaseCompanyQuota"
													:class="{ invalid: siteQuotaError.purchaseCompanyQuota }"
													:min="minCompanyQuota"
													:disabled="isUnlimitedCompanyQuota"
													:value="purchaseCompanyQuota"
													@change="handleSiteChangeElement"
													@keyup="handleSiteChangeElement"
													@mouseup="handleSiteChangeElement"
												/>
												<button
													class="plus"
													:disabled="isUnlimitedAccountQuota"
													:data-opetaion-type="this.operation.plus"
													@click="handleSitePurchaseCompanyQuota"
												>
													<span class="ic_set ic_plus"></span>
												</button>
											</span>
											<span class="txt">GB</span>
										</div>
										<p class="text-detail invalid" v-if="siteQuotaError.purchaseCompanyQuota">
											<i class="ic-16 ic-invalid"></i>
											{{ siteQuotaError.purchaseCompanyQuota }}
										</p>
										<!-- </p> -->
									</div>
								</form>
							</div>
						</div>
					</div>
					<!-- </div> // <div class="wrap_row"> 삭제 -->
					<!-- <div class="wrap_row"> -->
					<div class="group row" v-if="!isNew && isAccountOn">
						<label class="form_tit">
							<span class="txt">법인 카드</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form @submit.prevent="() => {}">
									<div class="group row">
										<!-- <p> -->
										<span class="txt">{{ site.usedCompanyCreditCardCount }}개</span>
										<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
										<div class="com_ipt number">
											<span class="com_number">
												<button
													class="minus"
													:disabled="purchaseCompanyCreditCardCount == 0"
													:data-opetaion-type="this.operation.minus"
													@click="handleSiteUsedCompanyCreditCardCount"
												>
													<span class="ic_set ic_minus"></span>
												</button>
												<input
													class="ipt_normal"
													type="number"
													name="quantity"
													data-type="purchaseCompanyCreditCardCount"
													:class="{ invalid: siteQuotaError.purchaseCompanyCreditCardCount }"
													:min="site.usedCompanyCreditCardCount"
													:value="purchaseCompanyCreditCardCount"
													@change="handleSiteChangeElement"
													@keyup="handleSiteChangeElement"
													@mouseup="handleSiteChangeElement"
												/>
												<button
													class="plus"
													:data-opetaion-type="this.operation.plus"
													@click="handleSiteUsedCompanyCreditCardCount"
												>
													<span class="ic_set ic_plus"></span>
												</button>
											</span>
											<span class="txt">개</span>
										</div>
										<p class="text-detail invalid" v-if="siteQuotaError.purchaseCompanyCreditCardCount">
											<i class="ic-16 ic-invalid"></i>
											{{ siteQuotaError.purchaseCompanyCreditCardCount }}
										</p>
										<!-- </p> -->
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="group row" v-if="!isNew && isAccountOn">
						<label class="form_tit">
							<span class="txt">개인카드 사용자</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form @submit.prevent="() => {}">
									<div class="group row">
										<!-- <p> -->
										<span class="txt">{{ site.usedPersonalCardCount }}개</span>
										<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
										<div class="com_ipt number">
											<span class="com_number">
												<button
													class="minus"
													:disabled="purchasePersonalCardCount == 0"
													:data-opetaion-type="this.operation.minus"
													@click="handleSitePurchasePersonalCardCount"
												>
													<span class="ic_set ic_minus"></span>
												</button>
												<input
													class="ipt_normal"
													type="number"
													name="quantity"
													data-type="purchasePersonalCardCount"
													:class="{ invalid: siteQuotaError.purchasePersonalCardCount }"
													:min="site.usedPersonalCardCount"
													:value="purchasePersonalCardCount"
													@change="handleSiteChangeElement"
													@keyup="handleSiteChangeElement"
													@mouseup="handleSiteChangeElement"
												/>
												<button
													class="plus"
													:data-opetaion-type="this.operation.plus"
													@click="handleSitePurchasePersonalCardCount"
												>
													<span class="ic_set ic_plus"></span>
												</button>
											</span>
											<span class="txt">개</span>
										</div>
										<p class="text-detail invalid" v-if="siteQuotaError.purchasePersonalCardCount">
											<i class="ic-16 ic-invalid"></i>
											{{ siteQuotaError.purchasePersonalCardCount }}
										</p>
										<!-- </p> -->
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="group row" v-if="!isNew && isAccountOn">
						<label class="form_tit">
							<span class="txt">화상대화</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form @submit.prevent="() => {}">
									<div class="group row">
										<!-- <p> -->
										<span class="txt">{{ site.purchaseMeetingRoomCount }}개</span>
										<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
										<div class="com_ipt number">
											<span class="com_number">
												<button
													class="minus"
													:disabled="purchaseMeetingRoomCount == 0"
													:data-opetaion-type="this.operation.minus"
													@click="handleSitePurchaseMeetingRoomCount"
												>
													<span class="ic_set ic_minus"></span>
												</button>
												<input
													class="ipt_normal"
													type="number"
													name="quantity"
													data-type="purchaseMeetingRoomCount"
													:class="{ invalid: siteQuotaError.purchaseMeetingRoomCount }"
													:min="site.usedMeetingRoomCount"
													:value="purchaseMeetingRoomCount"
													@change="handleSiteChangeElement"
													@keyup="handleSiteChangeElement"
													@mouseup="handleSiteChangeElement"
												/>
												<button
													class="plus"
													:data-opetaion-type="this.operation.plus"
													@click="handleSitePurchaseMeetingRoomCount"
												>
													<span class="ic_set ic_plus"></span>
												</button>
											</span>
											<span class="txt">개</span>
										</div>
										<p class="text-detail invalid" v-if="siteQuotaError.purchaseMeetingRoomCount">
											<i class="ic-16 ic-invalid"></i>
											{{ siteQuotaError.purchaseMeetingRoomCount }}
										</p>
										<!-- </p> -->
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="group row" v-if="!isNew && isAccountOn">
						<label class="form_tit">
							<span class="txt">세금계산서</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form @submit.prevent="() => {}">
									<div class="group row">
										<!-- <p> -->
										<span class="txt">{{ site.usedTaxBillCount }}개</span>
										<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
										<div class="com_ipt number">
											<span class="com_number">
												<button
													class="minus"
													:disabled="purchaseTaxBillCount == 0"
													:data-opetaion-type="this.operation.minus"
													@click="handleSiteUsedTaxBillCount"
												>
													<span class="ic_set ic_minus"></span>
												</button>
												<input
													class="ipt_normal"
													type="number"
													name="quantity"
													data-type="purchaseTaxBillCount"
													:class="{ invalid: siteQuotaError.purchaseTaxBillCount }"
													:min="site.usedTaxBillCount"
													:value="purchaseTaxBillCount"
													@change="handleSiteChangeElement"
													@keyup="handleSiteChangeElement"
													@mouseup="handleSiteChangeElement"
												/>
												<button
													class="plus"
													:data-opetaion-type="this.operation.plus"
													@click="handleSiteUsedTaxBillCount"
												>
													<span class="ic_set ic_plus"></span>
												</button>
											</span>
											<span class="txt">개</span>
										</div>
										<p class="text-detail invalid" v-if="siteQuotaError.purchaseTaxBillCount">
											<i class="ic-16 ic-invalid"></i>
											{{ siteQuotaError.purchaseTaxBillCount }}
										</p>
										<!-- </p> -->
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="group row" v-if="!isNew && isAccountOn">
						<label class="form_tit">
							<span class="txt">은행계좌 즉시조회</span>
							<span class="ic_set ic_import"></span>
						</label>
						<div class="form_data cell">
							<div class="com_radio">
								<form @submit.prevent="() => {}">
									<div class="group row">
										<!-- <p> -->
										<span class="txt">{{ site.usedBankAccountCount }}개</span>
										<!-- [D] 주문(As-is)에서 사용된 것과 같은 컨트롤 사용 -->
										<div class="com_ipt number">
											<span class="com_number">
												<button
													class="minus"
													:disabled="purchaseBankAccountCount == 0"
													:data-opetaion-type="this.operation.minus"
													@click="handleSitePurchaseBankAccountCount"
												>
													<span class="ic_set ic_minus"></span>
												</button>
												<input
													class="ipt_normal"
													type="number"
													name="quantity"
													data-type="purchaseBankAccountCount"
													:class="{ invalid: siteQuotaError.purchaseBankAccountCount }"
													:min="site.usedBankAccountCount"
													:value="purchaseBankAccountCount"
													@change="handleSiteChangeElement"
													@keyup="handleSiteChangeElement"
													@mouseup="handleSiteChangeElement"
												/>
												<button
													class="plus"
													:data-opetaion-type="this.operation.plus"
													@click="handleSitePurchaseBankAccountCount"
												>
													<span class="ic_set ic_plus"></span>
												</button>
											</span>
											<span class="txt">개</span>
										</div>
										<p class="text-detail invalid" v-if="siteQuotaError.purchaseBankAccountCount">
											<i class="ic-16 ic-invalid"></i>
											{{ siteQuotaError.purchaseBankAccountCount }}
										</p>
										<!-- </p> -->
									</div>
								</form>
							</div>
						</div>
					</div>
					<!-- </div> // <div class="wrap_row"> 삭제 -->
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import baseSite from '@/components/baseSite';
import baseSiteStatus from '@/components/baseSiteStatus';
import _ from 'lodash';

const ERROR = {
	purchaseUserCount: '',
	purchaseAccountQuota: '',
	purchaseCompanyQuota: '',
	purchaseCompanyCreditCardCount: '',
	purchasePersonalCardCount: '',
	purchaseMeetingRoomCount: '',
	purchaseTaxBillCount: '',
	purchaseBankAccountCount: '',
};

export default {
	mixins: [baseSite, baseSiteStatus],
	props: ['index', 'site', 'siteStatus', 'isNew'],
	data() {
		return {
			operation: {
				minus: 'minus',
				plus: 'plus',
			},
			siteQuotaError: _.cloneDeep(ERROR),
			purchaseUserCount: this.site.purchaseUserCount,
			purchaseAccountQuota: this.site.purchaseAccountQuota,
			purchaseCompanyQuota: this.site.purchaseCompanyQuota,
			purchaseCompanyCreditCardCount: this.site.purchaseCompanyCreditCardCount,
			purchasePersonalCardCount: this.site.purchasePersonalCardCount,
			purchaseMeetingRoomCount: this.site.purchaseMeetingRoomCount,
			purchaseTaxBillCount: this.site.purchaseTaxBillCount,
			purchaseBankAccountCount: this.site.purchaseBankAccountCount,
			isUnlimitedAccountQuota: this.site.purchaseAccountQuota === 0,
			isUnlimitedCompanyQuota: this.site.purchaseCompanyQuota === 0,
		};
	},
	computed: {
		minAccountQuota() {
			return this.site.usedAccountQuota || 2;
		},
		minCompanyQuota() {
			return this.site.usedCompanyQuota || 1;
		},
		iconClass() {
			let indexStr = this.index + 1;
			if (indexStr === 0) {
				indexStr = '_new';
			} else {
				indexStr = `${this.indexStr}_s`;
			}
			return `ic_bd${indexStr}`;
		},
	},
	methods: {
		setUnlimitedAccountQuota(isUnlimited) {
			if (this.isUnlimitedAccountQuota === isUnlimited) return;
			this.isUnlimitedAccountQuota = isUnlimited;
			let purchaseAccountQuota;
			if (isUnlimited) {
				purchaseAccountQuota = 0;
			} else {
				purchaseAccountQuota = Math.max(2, this.site.usedAccountQuota, this.site.purchaseAccountQuota);
			}
			this.purchaseAccountQuota = purchaseAccountQuota;
			this.site.purchaseAccountQuota = this.purchaseAccountQuota;
			this.$emit('changeSite', {
				site: this.site,
			});
			this.validateSite(this.site);
		},
		handleAccountQuotaLimitClick(e) {
			const isUnlimited = e.currentTarget.getAttribute('data-is-limited') === 'false';
			this.setUnlimitedAccountQuota(isUnlimited);
		},
		setUnlimitedCompanyQuota(isUnlimited) {
			if (this.isUnlimitedCompanyQuota === isUnlimited) return;
			this.isUnlimitedCompanyQuota = isUnlimited;
			let purchaseCompanyQuota;
			if (isUnlimited) {
				purchaseCompanyQuota = 0;
			} else {
				purchaseCompanyQuota = Math.max(1, this.site.usedCompanyQuota, this.site.purchaseCompanyQuota);
			}
			this.purchaseCompanyQuota = purchaseCompanyQuota;
			this.site.purchaseCompanyQuota = this.purchaseCompanyQuota;
			this.$emit('changeSite', {
				site: this.site,
			});
			this.validateSite(this.site);
		},
		handleCompanyQuotaLimitClick(e) {
			const isUnlimited = e.currentTarget.getAttribute('data-is-limited') === 'false';
			this.setUnlimitedCompanyQuota(isUnlimited);
		},

		getPlusMinusOperation(data, type) {
			let number = parseInt(data, 10);
			if (isNaN(number)) number = 0;

			if (this.operation.minus == type) {
				number--;
			} else if (this.operation.plus == type) {
				number++;
			} else {
				console.log('number');
			}

			return number;
		},

		handleSitePurchaseBankAccountCount(e) {
			const number = this.getPlusMinusOperation(
				this.purchasePersonalCardCount,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchaseBankAccountCount');
		},
		handleSiteUsedTaxBillCount(e) {
			const number = this.getPlusMinusOperation(
				this.purchasePersonalCardCount,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchaseTaxBillCount');
		},
		handleSitePurchaseMeetingRoomCount(e) {
			const number = this.getPlusMinusOperation(
				this.purchasePersonalCardCount,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchaseMeetingRoomCount');
		},
		handleSitePurchasePersonalCardCount(e) {
			const number = this.getPlusMinusOperation(
				this.purchasePersonalCardCount,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchasePersonalCardCount');
		},
		handleSiteUsedCompanyCreditCardCount(e) {
			const number = this.getPlusMinusOperation(
				this.purchaseCompanyCreditCardCount,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchaseCompanyCreditCardCount');
		},
		handleSitePurchaseCompanyQuota(e) {
			const number = this.getPlusMinusOperation(
				this.purchaseCompanyQuota,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchaseCompanyQuota');
		},
		handleSitePurchaseAccountQuota(e) {
			const number = this.getPlusMinusOperation(
				this.purchaseAccountQuota,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchaseAccountQuota');
		},
		handleSitePurchaseUserCount(e) {
			const number = this.getPlusMinusOperation(
				this.site.purchaseUserCount,
				e.currentTarget.getAttribute('data-opetaion-type'),
			);
			this.handleSiteChange(number, 'purchaseUserCount');
		},
		handleSiteChange(count, dataType) {
			const value = count;
			if (value === 0) {
				if (dataType === 'purchaseAccountQuota') {
					return this.setUnlimitedAccountQuota(true);
				}
				if (dataType === 'purchaseCompanyQuota') {
					return this.setUnlimitedCompanyQuota(true);
				}
			}
			this[dataType] = value;
			this.site[dataType] = this[dataType] || 0;
			this.$emit('changeSite', {
				site: this.site,
			});
			this.validateSite(this.site);
		},

		handleSiteChangeElement(e) {
			const dataType = e.currentTarget.getAttribute('data-type');
			const value = parseInt(e.currentTarget.value, 10);
			if (value === 0) {
				if (dataType === 'purchaseAccountQuota') {
					return this.setUnlimitedAccountQuota(true);
				}
				if (dataType === 'purchaseCompanyQuota') {
					return this.setUnlimitedCompanyQuota(true);
				}
			}
			this[dataType] = value;
			this.site[dataType] = this[dataType] || 0;
			this.$emit('changeSite', {
				site: this.site,
			});
			this.validateSite(this.site);
		},
	},
};
</script>
